import { useCallback } from 'react';
import { api } from "config";
import { toast } from "react-toastify";
export const useDownloadReport = () => {
    const downloadReport = useCallback(async () => {
        try {
            // Perform the API request to download the file
            const response = await api.get("download", {
                responseType: 'blob' // Set responseType to 'blob' to handle binary data (file)
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'result.xlsx'); // Specify the filename and extension
            document.body.appendChild(link);
            link.click();
            link.remove()
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }, []);
    const downloadSpecificTableReport = useCallback(async (file: string, name:string ) => {
        try {
          const response = await api.post("download_single_file", { filename: file }, {
            responseType: 'blob'
          });
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${name}.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.remove()
          window.URL.revokeObjectURL(url);
        } catch (error) {
          toast.error("Error downloading file");
        }
      }, []); 
    return {
        downloadReport,
        downloadSpecificTableReport
    };
};
