
import React from "react";
const CustomRadioButton = ({
    id,
    name,
    value,
    label,
    imageSrc,
    checked,
    onChange,
    title,
  }: any) => {
    console.log("checked", value);
    return (
      <div className="w-full max-w-[600px]">
        <label className={`border  p-2 py-4 items-center my-4 gap-x-4 flex justify-center rounded-xl border-[#033d44] ${checked? "border-2 ":""}`}>
          <input
            type="radio"
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className="hidden"
          />
          <div className="flex-col">
          <div className="flex">
        <img src={imageSrc} alt={label} className="w-10 h-10 mr-2" />
          <div className="gap-y-4">
          <h1 className="text-xl text-primary font-semibold">{label}</h1>
          <p className="text-lg text-primary">{title}</p>
          <div>
        </div>    
       
          <div>
          {checked &&
            <div className="flex gap-x-4 my-2">
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus
                nobis facilis, est animi ad quam harum architecto.
              </p>
            </div>
          }
          </div>
          </div>
          
          </div>
          
          </div>
        
          <div
          className={`border rounded-full border-gray-400 ml-auto  ${
            checked ? "border-[#033d44] w-[70px] h-[44px] -mt-14" : "w-10 h-10 -mt-6 "
          }`}
        >
          <div
            className={`w-[29px] h-[29px] border rounded-full border-gray-400 ml-auto mx-auto mt-[6px] ${
              checked ? "bg-[#033d44] border-[#033d44]" : "hidden"
            }`}
          />
        </div>
        </label>
        {/* <div>
          {checked &&
            <div className="flex gap-x-4 my-5 bg-[#A1ADA8] rounded-xl py-4 px-3">
  
              <img src={book} className="w-7 h-7" />
              
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minus
                nobis facilis, est animi ad quam harum architecto.
              </p>
            </div>
          }
        </div> */}
      </div>
    );
  };
export default CustomRadioButton
