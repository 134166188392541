import Input from "components/Input";
import Button from "components/Button";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { LOGININITIALVALUES, PATHS } from "constants/index";
import { loginSchema } from "utils/schema";
import { UserCredentials } from "types";
import { toast } from "react-toastify";
import { logo } from "assests";

const LoginForm = () => {
  const { handleSubmit, handleChange, values, touched, errors } = useFormik({
    initialValues: LOGININITIALVALUES,
    validationSchema: loginSchema,
    onSubmit: (values: UserCredentials) => {
      if (
        values.email === "admin@gmail.com" &&
        values.password === "admin123"
      ) {
        // Save user info to local storage
        const userData = {
          ...values,
          isAuthenticate: true,
        };

        localStorage.setItem("user", JSON.stringify(userData));
        toast.success("Login successful");
        window.location.reload();
      } else {
        console.log("Incorrect email or password");
        // Handle incorrect email or password here, such as showing an error message
      }
    },
  });

  return (
    <form
      className="w-full bg-[#FCFCFC] px-4 rounded md:shadow-md max-h-full min-w-[400px]"
      noValidate
      onSubmit={handleSubmit}
    >
      <div className="mx-4 -ml-1">
        <img src={logo} className="my-10 mx-auto" width={200} height={200} />
        <h1 className="text-3xl font-bold mb-10 leading-normal pt-2 flex justify-center items-center">
          Log In
        </h1>
        <Input
          placeholder="Email"
          name="email"
          error={touched.email && errors.email}
          className="mb-[19px] border ml-2"
          onChange={handleChange}
          value={values.email}
          title="Email"

        />
        <Input
          placeholder="*********"
          type="password"
          name="password"
          error={touched.password && errors.password}
          className="border ml-2"
          onChange={handleChange}
          value={values.password}
          title="Password"
        />

        <Button title={"Login"} type="submit" />
        <Link
          to={PATHS.FORGETPASSWORD}
          className="text-center text-[#898989] ml-2 text-lg my-6 justify-center items-center flex font-helvetica"
        >
          Forgot password?
        </Link>
      </div>
    </form>
  );
};
export default LoginForm;
