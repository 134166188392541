import { useEffect, useState } from "react";
import { useTable } from "react-table";
import { useMostFrequencyAnalysis } from "hooks/useMostFrequency";
import { useCountryRepresentative } from "hooks/useCountryRepresentative";
import { formatDate } from "utils/index";
import FrequencyTableView from "components/MostFrequentTable";
import TableSkeleton from "./TableSkeleton";
import CountryRepresentative from "components/CountryRepresentative";

type TProps = {
  columns: any[];
  data: any[];
  showTable?: boolean;
  CountryTable?: boolean;
  clickable?: boolean;
  className?: string
  grid?:boolean
  lastElementRef?: (node: HTMLElement | null) => void;
};

const Table = ({
  columns,
  data,
  showTable,
  CountryTable,
  clickable,
  className,
  grid=true,
  lastElementRef
}: TProps) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  const { Frequency, Records } = useMostFrequencyAnalysis();
  const { CountryRecords, countryRepresentative } = useCountryRepresentative();
  const [tableValues, setTableValues] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(showTable);
  const [countryRep, setCountryRep] = useState("");
  const handleClose = () => {
    setIsTableVisible(false);
  };
  const handleClick = (value: any) => {
    console.log(value)
    if (CountryTable) {
      setCountryRep(value);
    } else {
      setTableValues(value);
    }
    setIsTableVisible(true);
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (data && data.length > 0) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [data]);
  return (
    <>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <div className={`relative overflow-x-auto ${className}`}>
          <table className="w-full text-sm text-left border-main bg-green max-h-[700px]" {...getTableProps()}>
            <thead className="text-xs text-white uppercase bg-green border border-main sticky">
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps()}
                      className="px-7 py-4 border border-main bg-green  text-white font-bold text-sm uppercase tracking-wider"
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
                  >
                    {row.cells.map((cell: any) => (
                      <td
                        {...cell.getCellProps()}
                        className={`px-6 py-4 text-black border border-main ${!grid ? 'align-top':'align-middle'} ${clickable ? "cursor-pointer" : ""
                          }`}
                        onClick={() => {
                          if (
                            clickable &&
                            cell.row.values.col1 !== "All Countries"
                          ) {
                            handleClick(cell.row.values.col1);
                          }
                        }}
                      >
                        {cell.column.id === "Expiration Date"
                          ? formatDate(cell.value) : cell.column.id === 'logoSection' || cell.column.id.includes('Image Path') ? Array.isArray(cell.value) ? <RenderImages data={cell.value} grid={grid}/>: cell.value && cell.value !=='null'? <img src={process.env.REACT_APP_URL+cell.value} className="h-12 w-12"/>:"-"
                          : cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {isTableVisible && CountryTable && (
        <CountryRepresentative
          data={
            CountryTable
              ? CountryRecords
              : []
          }
          openModal={isTableVisible}
          isClose={handleClose}
          name={countryRep}
        />
      )}
      {isTableVisible && !CountryTable && (
        <FrequencyTableView
          data={
            Records
              ? Records
              : []
          }
          openModal={isTableVisible}
          isClose={handleClose}
          name={tableValues}
        />
      )}
    </>
  );
};

export default Table;

const RenderImages = ({data, grid}:any)=>{
  return(
    <div className={`${grid ? 'grid grid-cols-2 gap-4 md:grid-cols-3 items-center':''}`}>
      {data?.map((imageData:any)=>(
        imageData && imageData !=='null' ? <img className={`h-12 w-12 ${!grid ? 'mb-3':''}`} src={process.env.REACT_APP_URL+imageData}/>:<div>-</div>
      ))}
    </div>
  )
}