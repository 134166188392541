import { useCallback } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "state/store";
import { TCOUNTRYDISTRIBUTION, TDATABASEMANAGEMENTSLICE, TMISSINGPRODUCTTERM, TMISSINGPRODUCTTERMINCOUNTRY, TTRADEMARKRECORD, UNIQUEOWNERRECORD,TMISSINGMARK } from "./type"
import { setTrademarkAnalysisAction,setMissingProductTermAction,setMissingProductTermInCountriesAction,setUniqueOwnerAction, setCountryDistributionAction ,setMissingMarkAction,setShowResultsState} from ".";
export const useTrademarkAnalysis = () => {
    const { tableRecords, showResults,missingProductTerm ,missingProductTermInCountry,uniqueOwner,countryDistribution,missingMark} = useSelector<RootState, TDATABASEMANAGEMENTSLICE>(
        (state) => state.trademarkAnalysis
    );
    const dispatch = useAppDispatch();
    const setTrademarkAnalysis = useCallback(
        (payload: TTRADEMARKRECORD) => {
            dispatch(setTrademarkAnalysisAction(payload));
        },
        [dispatch]
    );
    const setShowResults = useCallback(
        (payload: boolean) => {
            dispatch(setShowResultsState(payload));
        },
        [dispatch]
    );
    const setMissingProductTerm = useCallback(
        (payload: TMISSINGPRODUCTTERM) => {
            dispatch(setMissingProductTermAction(payload));
        },
        [dispatch]
    );
    const setMissingMark = useCallback(
        (payload: TMISSINGMARK) => {
            dispatch(setMissingMarkAction(payload));
        },
        [dispatch]
    );
    const setCountryDistribution = useCallback(
        (payload: TCOUNTRYDISTRIBUTION) => {
            dispatch(setCountryDistributionAction(payload));
        },
        [dispatch]
    );
    const setMissingProductTermInCountries = useCallback(
        (payload: TMISSINGPRODUCTTERMINCOUNTRY) => {
            dispatch(setMissingProductTermInCountriesAction(payload));
        },
        [dispatch]
    );
    const setUniqueOwner = useCallback(
        (payload: UNIQUEOWNERRECORD) => {
            dispatch(setUniqueOwnerAction(payload));
        },
        [dispatch]
    );
    return {
        tableRecords,
        showResults,
        setShowResults,
        setTrademarkAnalysis,
        setMissingProductTerm,
        missingProductTerm,
        missingProductTermInCountry,
        uniqueOwner,
        setMissingProductTermInCountries,
        setUniqueOwner,
        setCountryDistribution,
        countryDistribution,
        setMissingMark,
        missingMark

    };
}