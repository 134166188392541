import React,{useRef} from "react";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import {  useState } from "react";
import BarChart from "components/BarChart";


const MissingProductTermsInTrademarks = () => {
  const {missingProductTerm}=useTrademarkAnalysisData()
  const [showTable, setShowTable] = useState(false);
  const [index, setIndex] = useState(0);
  function handleIndexUpdate(newIndex: number) {
    setIndex(newIndex);
    setShowTable(true);
  }
  const chartData = {
    labels: missingProductTerm?.map((item) => item.product_term),
    datasets: [
      {
        label: "Missing Product Terms In Trademarks",
        data: missingProductTerm?.map((item) => item.missing_count),
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: "#033d44",
        borderColor: "#033d44",
      },
    ],
  };
  const componentRef = useRef();
  return (
    // <div className="w-full lg:w-1/2 xl:w-1/2 px-4 mb-4">
    <div className="w-full">
   
          <div className="rounded-md mt-4 p-4 border border-[#DCDCDC] bg-[#ECECEC]">
            <BarChart
              data={missingProductTerm}
              chartData1={chartData}
              index={index}
              onIndexUpdate={handleIndexUpdate}
              isProduct={1}
              
            />
          </div>

    
    </div>
  );
}  
export default MissingProductTermsInTrademarks;
