

import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { Map } from "components/MapChart/GeoChart";
import { RowSelection } from "components/MapChart/RowTable";
import MapTable from "components/MapChart/Table";
import InfoCardsContainer from "components/InfoCardContainer/index";
import { api } from "config";
import { geoData } from "temp";
import { COUNTRYREPRESENTATION, COUNTRYREPRESENTATION2 } from "constants/index";
import TableSkeleton from "components/Table/TableSkeleton";

const MissingMarksTable = () => {
  const { missingMark } = useTrademarkAnalysisData();
  const [selection, setSelection] = useState<any[]>([]);
  const [fullHeight, setFullHeight] = useState<boolean>(false);
  const graphRef = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [initialData, setInitialData] = useState<any[]>([]);

  const handleSelectChange = (selectedItems: any[]) => {
    setSelection(selectedItems);
  };

  const [trademarkTable, setTrademarkTable] = useState<any[]>([]);

  // Fetch initial data on component mount
  useEffect(() => {
    fetchInitialData();
  }, []); // Only run once on component mount

  // Fetch data when selection changes
  useEffect(() => {
    if (selection.length > 0) {
      fetchData();
    }
  }, [selection]); // Depend only on selection
  
  const fetchData = async () => {
    setIsLoading(true);
    const promises = selection.map(async (country) => {
      try {
        const { data } = await api.get(`get_country_missing_trademarks?country_name=${country.col1}`);
        setTrademarkTable((prevTable: any) => [
          ...prevTable.filter((item: any) => item.col1 !== data[0].Country),
          {
            col1: data[0].Country,
            col2: data[0]['Missing Trademarks'].split(",").map((item: string) => item.trim()),
            logoSection:data[0]['Image Paths'].split(",").map((item: string) => item.trim())
          }
        ]);
      } catch (error) {
        console.error(`Error fetching data for ${country.col1}:`, error);
        return null;
      }
    });

    try {
      await Promise.all(promises);
      setTrademarkTable((prevTable: any) => {
        const currentCountries = selection.map((country) => country.col1);
        return prevTable.filter((item: any) => currentCountries.includes(item.col1));
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInitialData = async () => {
    try {
      const { data } = await api.get(`get_country_missing_trademarks`);
      const transformedData = data.map((item: { [x: string]: string[]; Country: any; }) => ({
        col1: item.Country,
        col2: item['Missing Trademarks'].map((trademark: string) => {
          if (trademark.includes('logo')) {
            return trademark.replace(/logo/g, "");
          }
          return trademark;
        }),
        logoSection:item['Image Paths'].map((item: string) => item.trim())
      }));

      setTrademarkTable(transformedData);
      setInitialData(transformedData);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  const missingMarks = useMemo(
    () =>
      missingMark?.data?.map((item: any, index) => {
        return {
          col1: item.Country,
          col2: item["No of Missing Trademarks"],
        };
      }) || [],
    [missingMark]
  );

  const tableData = selection.length ? trademarkTable : initialData;
  const [selectTable,setSelectTable]=useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      if (missingMarks && missingMarks.length > 0) {
        setSelectTable(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [missingMarks]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (tableData && tableData.length > 0) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [tableData]);

  useEffect(() => {
    // Set loading to true initially
    setIsLoading(true);
  }, [tableData]);

  const getMissingTrademarksCount = (selection: any[], missingMarks: any[]) => {
    return selection.reduce((acc, country) => {
      const countryData = missingMarks.find((item: any) => item.col1 === country.col1);
      return acc + (countryData ? parseInt(countryData.col2) : 0)
    }, 0);
  };

  return (
    <div className="flex-col items-center justify-between mx-4">
      <h1 className="font-bold text-4xl my-8 justify-center flex item-center text-[#033d44]">
        Missing Coverage
      </h1>
      <div ref={graphRef}>
        <div className="my-8 flex flex-wrap -mx-4">
          <InfoCardsContainer
            heading1="Missing TradeMarks"
            value1={selection.length > 0 ? getMissingTrademarksCount(selection,missingMarks) : missingMark?.total_missing_trademarks || 0}
            heading2="No of Countries"
            value2={selection.length > 0 ? selection.length : missingMark?.total_countries || 0}
          />

        </div>
        <div className="w-full justify-center items-center flex">
          <Map
            geoData={geoData}
            numData={missingMarks}
            width={850}
            height={500}
            selection={selection}
          />
        </div>
        <div className="flex flex-wrap mt-8 -mx-4">
          <div className={`${fullHeight ? 'max-h-full' : 'max-h-[400px]'} overflow-auto w-full md:w-1/2 lg:w-1/2 xl:w-1/2 px-4 mb-4`}>
          {selectTable ? (
            <TableSkeleton />
          ) : (
            <RowSelection
              data={missingMarks}
              columns={COUNTRYREPRESENTATION2}
              select={selection}
              onSelectChange={handleSelectChange}
              showSelector={!fullHeight}
            />)}
          </div>
          <div className="max-h-[400px] overflow-auto w-full md:w-1/2 lg:w-1/2 xl:w-1/2 px-2 mb-4">
          {isLoading ? (
            <TableSkeleton />
          ) : (
            tableData.map((rowData: any, index: number) => (
              <MapTable
                key={index}
                data={rowData.col2.map((item: any, index:number) => ({ col1: rowData.col1, col2: item, ...rowData?.logoSection ? {logoSection: rowData?.logoSection[index]}:{}}))}
                columns={COUNTRYREPRESENTATION}
                index={index}
              />
            ))
          )}
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissingMarksTable;
