

import { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";
import close_icon from "../../assests/icons/close_icon.png";
import { api } from "config";
import InfiniteScroll from "react-infinite-scroll-component";
import { UNIQUEOWNERSCOLUMNS } from "../../constants";
import { useTable } from "react-table";
import Loader from "components/Loader";
import TableSkeleton from "components/Table/TableSkeleton";
import { useDownloadReport } from "hooks/useDownloadReport";
interface IProp {
  openModal: boolean;
  isClose: () => void;
  title: string;
  reportName?: string
}

const ScrollableChartTableView = ({ openModal, isClose, title, reportName }: IProp) => {
  const [showTable, setShowTable] = useState(openModal);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { downloadSpecificTableReport } = useDownloadReport()
  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    setLoading(true); // Set loading to true before fetching data
    api
      .get(`owner_discrepancy?page=${page}`)
      .then((res) => {
        const data = res.data.data.unique_owners.map((item: any) => ({
          col1: item["Unique Owner Name"],
          col2: item["Count"],
        }));

        setItems((prevItems: any) => [...prevItems, ...data]);

        if (data.length > 0) {
          setPage((prevPage) => prevPage + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
        setHasMore(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching data
      });
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: UNIQUEOWNERSCOLUMNS, data: items });

  const divElement: any = document.querySelector('.infinite-scroll-component');
  if (divElement) {
    divElement.style.overflow = 'hidden';
  }
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Always show the skeleton for at least 3 seconds
    const timer = setTimeout(() => {
      if (items && items.length > 0) {
        setIsLoading(false);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [items]);
  return (
    <div className="text-black">
      <Modal
        isOpen={openModal}
        onRequestClose={isClose}
        contentLabel="Chart Data Modal"
        shouldCloseOnOverlayClick={true}
        id='uniqueOwnersModal'
      >
        <div className="mx-auto rounded-md">
          <div onClick={() => {
            setShowTable(false);
            isClose();
          }} className="-mt-6 float-right w-4 h-4 cursor-pointer">
            <img src={close_icon} alt="close" />
          </div>
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">{title}</h1>
            <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 font-bold py-3 px-4"
              onClick={() => downloadSpecificTableReport(reportName!, "Unique Owners")}
            >Download Table Data</button>
          </div>
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={loading ? <div className="flex justify-center items-center my-2"><Loader /></div> : ""}
            scrollableTarget="uniqueOwnersModal"
            className="h-full"
          >
            {isLoading ? (
              <TableSkeleton />
            ) : (<table
              className="w-full text-sm text-left border-main bg-green"
              {...getTableProps()}
            >
              <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-6 py-4 text-black border border-main"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>)}

          </InfiniteScroll>
        </div>
      </Modal>
    </div>
  );
};

export default ScrollableChartTableView;
