

import { useState, useEffect } from "react";
import Modal from "react-modal";
import Table from "components/Table";
import close_icon from "../../assests/icons/close_icon.png";
import { api } from "config";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTable } from "react-table";
import { useDownloadReport } from "hooks/useDownloadReport";
interface IProp {
  data: any;
  openModal: boolean;
  isClose: () => void;
  column: any;
  title: string;
  endpoint?: string;
  params?: string;
  fields?: any[];
  paramsValue?: string;
  reportName?: string
}

const ChartTableView = ({ data, openModal, isClose, column, title, endpoint, params, fields, paramsValue, reportName }: IProp) => {
  const [showTable, setShowTable] = useState(openModal);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { downloadSpecificTableReport } = useDownloadReport()
  useEffect(() => {
    if (!data) fetchMoreData()
  }, [title]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: column, data: items });
  const divElement: any = document.querySelector('.infinite-scroll-component');

  // Check if the element is found
  if (divElement) {
    // Set overflow to hidden
    divElement.style.overflow = 'hidden';
  }
  const fetchMoreData = () => {
    setLoading(true); // Set loading to true before fetching data
    api
      .get(`${endpoint}?${params ? `${params}=${paramsValue}&` : ''}page=${page}`)
      .then((res) => {
        const newData = res.data.data;
        const tableData: any = []
        newData
          .map((item: any) => {
            const data: any = {}
            fields?.map((field: any, index: number) => {
              if(field === 'Image Path') data['logoSection'] = item[field] ? item[field]:null
              else data[`col${index + 1}`] = item[field] ? item[field] : field === 'Reg. no.' && item['Reg. no'] ? item['Reg. no'] : item['App. date'] ? item['App. date'] : '-'
            })
            tableData.push(data)
          })
        setItems((prevItems: any) => [...prevItems, ...tableData]);

        if (newData.length > 0) {
          setPage((prevPage) => prevPage + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching data:", err);
        setHasMore(false);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching data
      });
  };

  return (
    <div className="text-black">
      <Modal
        isOpen={openModal}
        onRequestClose={() => isClose()}
        contentLabel="Chart Data Modal"
        shouldCloseOnOverlayClick={true}
        id='chartDataeModal'
      >
        <div className="mx-auto rounded-md">
          <div
            onClick={() => {
              setShowTable(false);
              isClose();
            }}
            className="-mt-6 float-right w-4 h-4 cursor-pointer"
          >
            <img src={close_icon} alt="close" />
          </div>
          <div className="flex items-center justify-between mb-5">
            <h1 className="text-3xl font-bold my-4 text-[#033d44] justify-center items-center flex">{title}</h1>
            <button className="bg-green text-white cursor-pointer rounded-md hover:bg-green-700 font-bold py-3 px-4"
              onClick={() => downloadSpecificTableReport(reportName!, title)}
            >Download Table Data</button>
          </div>
          {!data ?

            <div className="overflow-hidden">
              <div id="scrollableDiv" style={{ height: "700px", overflow: "auto" }} >
                <InfiniteScroll
                  dataLength={items.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="chartDataeModal"
                  className="h-full"
                >
                  <table
                    className="w-full text-sm text-left border-main bg-green "
                    {...getTableProps()}
                  >
                    <thead className="text-xs text-white uppercase bg-green border border-main sticky">
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              className="px-7 py-4 border border-main bg-green text-white font-bold text-sm uppercase tracking-wider"
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {rows.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            {...row.getRowProps()}
                            className="border odd:bg-[#FBFBFB] even:bg-[#A1ADA8] border-b border-main"
                          >
                            {row.cells.map((cell) => (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 text-black border border-main"
                              >
                                {
                                 cell.column.id === 'logoSection' ? cell.value && cell.value !=='null'? <img src={process.env.REACT_APP_URL+cell.value} className="h-12 w-12"/>:"-" :
                                cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </InfiniteScroll>
              </div>
            </div>
            : <Table columns={column} data={data} grid={title !== 'Missing Product Terms In Trademark' ? true:false} />}
        </div>
      </Modal>
    </div>
  );
};

export default ChartTableView;
