import { createSlice } from "@reduxjs/toolkit";
import { TCOUNTRYDISTRIBUTION, TDATABASEMANAGEMENTSLICE, TMISSINGMARK, TMISSINGPRODUCTTERM, TMISSINGPRODUCTTERMINCOUNTRY, TTRADEMARKRECORD, UNIQUEOWNERRECORD } from "./type";
const initialState: TDATABASEMANAGEMENTSLICE = {
    tableRecords: null,
    missingProductTerm:null,
    showResults:false,
    missingProductTermInCountry:null,
    uniqueOwner:null,
    countryDistribution:null,
    missingMark:null

}
const trademarkAnalysisSlice = createSlice({
    name: "trademarkAnalysis",
    initialState,
    reducers: {
        setTrademarkAnalysisAction: (state, action: { payload: TTRADEMARKRECORD }) => {
            return {
                ...state,
                tableRecords: action.payload,
            };
        },
        setShowResultsState:(state, action:{payload:boolean})=>{
            return {
                ...state,
                showResults:action.payload
            };
        },
        setMissingProductTermAction: (state, action: { payload: TMISSINGPRODUCTTERM }) => {
           
            return {
                ...state,
                missingProductTerm: action.payload,
                showResults:true
            };
        },
        setMissingMarkAction: (state, action: { payload: TMISSINGMARK }) => {
           
            return {
                ...state,
                missingMark: action.payload,
                showResults:true
            };
        },
        setCountryDistributionAction: (state, action: { payload: TCOUNTRYDISTRIBUTION }) => {
          
            return {
                ...state,
                countryDistribution: action.payload,
                showResults:true
            };
        },
        setMissingProductTermInCountriesAction: (state, action: { payload: TMISSINGPRODUCTTERMINCOUNTRY }) => {
          
            return {
                ...state,
                missingProductTermInCountry: action.payload,
                showResults:true
            };
        },
        setUniqueOwnerAction: (state, action: { payload: UNIQUEOWNERRECORD }) => {
          
            return {
                ...state,
                uniqueOwner: action.payload,
                showResults:true
            };
        },
        
    }
})
export const { setTrademarkAnalysisAction ,setMissingProductTermAction,setMissingProductTermInCountriesAction,setUniqueOwnerAction,setCountryDistributionAction,setMissingMarkAction, setShowResultsState} = trademarkAnalysisSlice.actions;

export default trademarkAnalysisSlice.reducer;