import { Routes, Route, Navigate } from "react-router-dom";
import { PATHS } from "constants/index";
import Dashboard from "page/DashBoard";
import DomainAnalysis from "page/DomainAnalysis"
import BlockchainCertification from "page/BlockchainCertification"
import AddTradeMark from "page/AddTradeMark";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={PATHS.HOME} element={<Dashboard />} />
      <Route path={PATHS.PUNNYCODE} element={<DomainAnalysis />} />
      <Route path={PATHS.BLOCKCHAINCERTIFICATION} element={<BlockchainCertification/>}/>
      <Route path={PATHS.VERIFICATION} element={<AddTradeMark/>} />
      <Route path="*" element={<Navigate to={PATHS.HOME} />} />
    </Routes>
  );
};
