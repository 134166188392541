import React,{useEffect, useRef} from "react";
import { useTrademarkAnalysisData } from "hooks/useTrademarkAnalysis";
import { useMemo, useState } from "react";
import BarChart from "components/BarChart";
import Loader from "components/Loader";
import { api } from "config";

const MissingProductTermsInCountry = () => {
  const {missingProductTermInCountry}=useTrademarkAnalysisData()
  const [showTable, setShowTable] = useState(false);
  const [index, setIndex] = useState(0);
  function handleIndexUpdate(newIndex: number) {
    setIndex(newIndex);
    setShowTable(true);
  }
  const chartData = {
    labels: missingProductTermInCountry?.map((item) => item.product_term),
    datasets: [
      {
        label: "Missing Product Terms In Country",
        data: missingProductTermInCountry?.map((item) => item.missing_count),
        borderWidth: 1,
        borderRadius: 20,
        backgroundColor: "#033d44",
        borderColor: "#033d44",
      },
    ],
  };
  return (
    
    // <div className="w-full lg:w-1/2 xl:w-1/2 px-4 mb-4">
       <div className="w-full">   
         <div className=" rounded-md mt-4 p-4 border border-[#DCDCDC] bg-[#ECECEC]">
        <BarChart
          data={missingProductTermInCountry}
          chartData1={chartData}
          index={index}
          onIndexUpdate={handleIndexUpdate}
          isProduct={2}
         
        />
         
      
      </div>
     
    </div>
  );
};
export default MissingProductTermsInCountry;
