

import WrapperLayout from 'components/Layout';
import Form2 from './Form';

const AddTradeMark = () => {
  return (
  
      <div className='flex w-full'><Form2/></div>

    
  )
}

export default AddTradeMark;