
import React from 'react';

export const ProgressBar = ({ percentage,value }: any) => {
  return (
  <div  style={{ width: '100%', maxWidth:'1000px'}} className='flex-col justify-center items-center'> 
    <h2 className="font-bold text-3xl text-green mb-3">Preparing Your Report</h2>
      <div style={{ width: '100%', backgroundColor: '#f0f0f0', borderRadius: '5px', padding: '3px', position: 'relative' }}>
      <div style={{ width: '100%', height: '20px', backgroundColor: '#f0f0f0', borderRadius: '5px', overflow: 'hidden' }}>
        <div style={{ width: `${percentage}%`, height: '100%', background: "linear-gradient(90deg, #033d44, #03b97e)", borderRadius: '5px', transition: 'width 0.3s ease-in-out' }}></div>
      </div>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#033d44', fontWeight: 'bold' }}>{percentage}%</div>
    </div>
    <h2 className="font-bold text-xl text-green mb-3">{value}</h2>
    </div>
    
  );
};

export default ProgressBar;
