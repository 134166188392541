import PunnyCode from "./components/PunnyCode"
import WrapperLayout from 'components/Layout';
import DomainVariations from "./components/DomainVariations";
import DomainChecker from "./components/DomainChecker";
const DomainAnalysis = () => {
    return (
        <WrapperLayout>
        <PunnyCode/>
        <DomainVariations/>
        <DomainChecker/>
        </WrapperLayout>
    )
}
export default DomainAnalysis