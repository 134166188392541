import React from 'react';

type Option = {
  value: string;
  label: string;
};

type IProps = {
  options: Option[];
  selectedOptions: Option[];
  setSelectedOptions: any
};

const CheckboxGroup = ({ options, selectedOptions, setSelectedOptions }: IProps) => {
  const selectAllOptions = (event:any) =>{
    if(event.target.checked){
      setSelectedOptions(options)
    }
    else{
      setSelectedOptions([])
    }
  }
  const handleCheckboxChange = (value: string) => {
    const isChecked = selectedOptions.some(option => option.value === value);
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions: any[]) =>
        prevSelectedOptions.filter(option => option.value !== value)
      );
    } else {
      setSelectedOptions((prevSelectedOptions: any) =>
        [...prevSelectedOptions, options.find(option => option.value === value)!]
      );
    }
  };

  return (
    <div className="w-full  flex-wrap max-h-[200px] justify-between flex mt-3 ">
      <div className="flex items-center w-full sm:w-[50%] md:w-[50%] cursor-pointer mb-3">
          <input
            type="checkbox"
            id='select-all'
            value='selectAll'
            className="mr-2 checked:bg-green accent-[#033d44]"
            onChange={(e:any)=>selectAllOptions(e)}
            style={{ backgroundColor: 'green', width: '20px', height: '20px' }}
          />
          <label htmlFor='select-all'>Select All</label>
        </div>
        <div className='flex flex-wrap'>
      {options.map(option => (
        <div key={option.value} className="flex items-center w-full sm:w-[50%] md:w-[50%] cursor-pointer">
          <input
            type="checkbox"
            id={option.value}
            value={option.value}
            checked={selectedOptions.some(selectedOption => selectedOption.value === option.value)}
            onChange={() => handleCheckboxChange(option.value)}
            className="mr-2 checked:bg-green accent-[#033d44]"
            style={{ backgroundColor: 'green', width: '20px', height: '20px' }}
          />
          <label htmlFor={option.value}>{option.label}</label>
        </div>
      ))}
      </div>
    </div>
  );
};

export default CheckboxGroup;
