import React from 'react'
import InfoCards from 'components/InfoCards'

type InfoCardsContainerProps = {
  heading1: string | [string],
  value1: number | string,
  heading2: string | [string],
  value2: number | string |any,
  handleClickAction?: () => void
}
const InfoCardsContainer = ({ heading1, value1, heading2, value2, handleClickAction }: InfoCardsContainerProps) => {
  return (
    <>
      <div className="w-full md:w-[47%]lg:w-[47%] xl:w-[47%] px-0 mb-4 mx-3">
        <InfoCards heading={heading1} value={value1} valueclass="text-2xl" />
      </div>
      <div className="w-full md:w-[47%]lg:w-[47%] xl:w-[47%] px-0 mb-4 cursor-pointer mx-3" onClick={handleClickAction}>
        <InfoCards heading={heading2} value={value2} valueclass="text-2xl" />
      </div>
    </>
  );
};

export default InfoCardsContainer