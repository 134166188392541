import { useState } from "react";
import {
  dashboard,
  analytics,
  settings,
  report,
  search,
  hamburger,
  whiteLogo,
  noTextLogo
} from "assests";
import { Link } from "react-router-dom";
import ham_icon from "../../assests/icons/hamburger_icon.png";
import { toast } from "react-toastify";

const LeftSideBar = () => {
  const [open, setOpen] = useState(false);
  const Menus = [
    { title: "Dashboard", src: dashboard, link: "/" },
    { title: "Schedule ", src: report, link: "#" },
    { title: "Search", src: search, link: "#" },
    { title: "Blockchain Certification", src: analytics, link: "https://app.bernstein.io/abion", target: true },
    { title: "Domain Analysis", src: search, link: "/domain-analysis" },
    { title: "Add TradeMarks", src: analytics, link: "/verification" },
    {title:"Gap Fill", src: analytics, link: "#" },
    { title: "Analytics", src: analytics, link: "#" },
    { title: "Trawl Leads", src: analytics, link: "#" },
    { title: "Settings", src: settings, gap: true, link: "#" },
    { title: "Logout", src: settings, link: "#" },
  ];

  const logoutUser = () => {
    localStorage.removeItem("user");
    toast.success("Logout successful");
    window.location.reload();
  };

  return (
    <div className="flex h-full">
      <div
        className={`fixed ${open ? "w-[240px] max-h-fit" : "w-20 "}
          bg-[#033d44] h-screen p-5 pt-8 relative duration-300`}
      >
        <img
          src={ham_icon}
          className={`absolute cursor-pointer right-4 top-[2.3rem] w-5  ${
            !open && "rotate-180 right-8 "
          }`}
          style={{ filter: "invert(100%)" }}
          onClick={() => setOpen(!open)}
        />

        <div className="flex gap-x-2 items-center">
          <Link to="/">
            <img
              src={whiteLogo}
              className={`${!open && "hidden"} -mt-[1.8rem]`}
              width={150}
              height={150}
            />
          </Link>
        </div>
        <ul className={`${open ? "-mt-5":"pt-6"}`}>
          {Menus.map((Menu, index) => (
            <Link
              to={Menu.link}
              key={index}
              {...(Menu.title === "Logout" && {
                onClick: () => {
                  logoutUser();
                },
              })}
              target={Menu.target ? '_blank' : ''}
            >
              <li
                className={`flex  rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-300 text-sm items-center gap-x-4 
                  ${Menu.gap ? "mt-9" : "mt-2"} ${index === 0 && "bg-light-white"}`}
              >
                <img
                  src={Menu.src}
                  className="w-5 h-5 object-contain"
                  style={{ filter: "invert(100%)" }}
                />

                <span
                  className={`${
                    !open && "hidden"
                  } text-[#dcdcdc] origin-left duration-200`}
                >
                  {Menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default LeftSideBar;
