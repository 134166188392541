// import { api } from "config"; // Assuming "config" contains your API configuration

// export const generateAndDownloadPDF = async (reportData: any) => {
//   try {
//     const formData = new FormData();

//     const fields = [
//       "missingCoverageText",
//       "totalCountryRepresentatives",
//       "totalCountryRepresentativesText",
//       "mostFrequentMark",
//       "mostFrequentMarkText",
//       "topCountriesMissingMostMarks",
//       "topCountriesMissingMostMarksText",
//       "statusDistribution",
//       "statusDistributionText",
//       "mostVulnerableNonUseTradeMarks",
//       "mostVulnerableNonUseTradeMarksText",
//       "missingProductTermsInCountry",
//       "missingProductTermsInCountryText",
//       "missingProductTermsInTradeMarks",
//       "missingProductTermsInTradeMarksText",
//       "tradeMarkMostMissingClasses",
//       "tradeMarkMostMissingClassesText",
//       "top5Classes",
//       "top5ClassesText",
//     ];

//     fields.forEach((field) => {
//       formData.append(formatFieldName(field), reportData[field]);
//     });

//     if (reportData.logoFile) {
//       formData.append("logo", reportData.logoFile, "logo.png");
//     }

//     const response = await api.post("/generate-pdf", formData, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//       },
//       responseType: "blob", // Ensure response type is set to blob
//     });

//     console.log('response.data', response.data)
//     if (response.status >= 200 && response.status < 300) {
//       const blob = new Blob([response.data], { type: "application/pdf" });
//       const url = window.URL.createObjectURL(blob);
//       const a = document.createElement("a");
//       a.href = url;
//       a.download = "report.pdf";
//       document.body.appendChild(a);
//       a.click();
//       a.remove();
//     } else {
//       console.error("Failed to generate PDF", response.statusText);
//     }
//   } catch (error) {
//     console.error("Error while generating PDF", error);
//   }
// };

// const formatFieldName = (fieldName: string) => {
//   return fieldName
//     .replace(/([A-Z])/g, " $1")
//     .replace(/^./, (str) => str.toUpperCase())
//     .replace("Text", " Text");
// };




import { api } from "config"; // Assuming "config" contains your API configuration

export const generateAndDownloadPDF = async (reportData: any) => {
  try {
    const formData = new FormData();

    // Append each field from reportData to formData
    Object.keys(reportData).forEach((key) => {
      if (key === 'logo') {
        formData.append('logo', reportData.logo, 'logo.png');
      } else {
        formData.append(key, reportData[key]);
      }
    });

    const response = await api.post("/generate-pdf", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: "blob", // Ensure response type is set to blob
    });

    if (response.status >= 200 && response.status < 300) {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      console.error("Failed to generate PDF", response.statusText);
    }
  } catch (error) {
    console.error("Error while generating PDF", error);
  }
};


