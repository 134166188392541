import { useState } from "react"
import { useDomainAnalysis } from "hooks/useDomainAnalysis";
const DomainVariations = () => {
    const { getDomainVariations,downloadReport } = useDomainAnalysis()
    const [variations, setVariations] = useState<any[]>([])
    const [variationsData, setVariationsData] = useState("")
    const [downloadReportStatus, setDownloadReportStatus] = useState(false);
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const { domains } = await getDomainVariations(variationsData?.split("\n"))
        setVariations(domains)
    }
    const downloadReportData = async () => {
        setDownloadReportStatus(true);
        try {
          await downloadReport({filename:"domain_options"}, 'Domain Variations');
        } catch (error) {
        } finally {
          setDownloadReportStatus(false);
        }
      };
    return (
        <div className="container mx-auto mt-10">
            <h3 className="text-2xl text-black font-bold mb-5">Domain Variations</h3>
            {variations?.length ? <h2 className="font-bold text-2xl text-black mb-3 text-center">Total no. of Varations: {variations.length}</h2> : null}
            <form onSubmit={handleFormSubmit}>
                <div className="block sm:flex justify-between">
                    <textarea
                        id="input"
                        rows={10}
                        className="h-full focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                        placeholder="Enter Domain"
                        required
                        value={variationsData}
                        onChange={(e) => setVariationsData(e.target.value)}
                    ></textarea>
                    <div
                        id="domainVariations"
                        className="h-[268px] overflow-y-auto mt-3 sm:mt-0 focus:outline-none shadow-xl border-4 border-[#033d44] resize-none block p-2.5 w-full sm:w-[48%] text-black bg-[#fbfbfb] rounded-2xl border border-mainBlue focus:ring-mainBlue focus:border-mainBlue"
                    >
                        {variations.map((variation, index) => (
                            <p key={index}>{variation}</p>
                        ))}
                    </div>
                </div>
                <div className="flex mt-8 justify-center">
                    <button className="text-[#dcdcdc] hover:bg-opacity-50 bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5">Submit</button>
                    <button type="button"
                    onClick={() => downloadReportData()}
                    disabled={!variations?.length || downloadReportStatus} className="hover:bg-opacity-50 text-[#dcdcdc] bg-[#033d44] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 ml-3 disabled:opacity-50 disabled:pointer-events-none"> {downloadReportStatus ? "Downloading..." : "Download All Variations"}</button>
                </div>
            </form>
        </div>
    )
}
export default DomainVariations