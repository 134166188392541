import { useState,useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Input from "components/Input";
import { icon3 } from "./../../../../assests";
import { useFormik } from "formik";
import { CONTACTINITIALVALUES } from "constants/index";
import { contactSchema } from "utils/schema";

export const ContactAccordion = ({onSubmit,previousValues}: any) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [valid, setValid] = useState(true);


  const handlePhoneNumberChange = (value: string) => {
    setPhoneNumber(value);
    setValid(validatePhoneNumber(value));
    // 
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };
  useEffect(() => {
    if (previousValues) {
      setFieldValue("firstName",previousValues.firstName);
      setFieldValue("lastName", previousValues.lastName);
      setFieldValue("email",previousValues.email);
      setFieldValue("phoneNumber", previousValues.phoneNumber);
      setFieldValue("note",previousValues.note);

    }
  }, [previousValues]);
  const { handleSubmit, handleChange,setFieldValue, values, touched, errors } = useFormik({
    initialValues: CONTACTINITIALVALUES,
    validationSchema: contactSchema,
    onSubmit: (values: any) => {
      setFieldValue("phoneNumber", phoneNumber);
      onSubmit(values)
      console.log(values);
      
      // Handle incorrect email or password here, such as showing an error message
    },
  });

  return (
        <div>
          <h2 className="text-2xl font-semibold mb-[20px] cursor-pointer text-primary text-left my-7 flex justify-center">
            Contact Details
          </h2>
          <form noValidate onSubmit={handleSubmit}>
            <div className="flex gap-x-3 mt-7 mobile:flex-col">
              <Input
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && errors.firstName}
                className="border-b-2  max-w-[300px]  rounded-none "
                title="First Name"
                placeholder="Enter Your First Name"
              />
              <Input
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && errors.lastName}
                className="border-b-2  max-w-[300px]  rounded-none "
                title="Last Name"
                placeholder="Enter Your Last Name"
              />
            </div>
            <Input
              name="email"
              value={values.email}
              onChange={handleChange}
              error={touched.email && errors.email}
              className="border-b-2 -mt-2  rounded-none max-w-[600px]"
              title="Email"
              placeholder="Enter Your Email"
            />
            <div className="mt-12">
              <PhoneInput
                country={"us"} // Set default country
                value={values.phoneNumber} // Pass phone number value
                onChange={handlePhoneNumberChange} // Handle phone number change
                containerStyle={{
                  borderBottom: "2px solid #001E12",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  borderRadius: "5px",
                  marginTop: "15px",
                  maxWidth:"600px"
                }}
                inputStyle={{
                  border: "none",
                  backgroundColor: "#FBFBFB",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  fontSize: "24px",
                }}
                searchStyle={{
                  padding: "20px",
                  height: "24px",
                  border:"2px solid #001E12"
                }}
                
              />
            </div>
            <h2 className="text-xl font-semibold mt-[20px] cursor-pointer text-primary text-left my-7  max-w-[600px]  flex ">
              In case there is other information that could help us during the
              lawyer's check,
              <br /> please leave it in the note below.
            </h2>
            <div className="mt-10 max-w-[600px]">
              <label className="text-green text-[16px] bg-white font-bold">
                Note (Optional)
              </label>
              <textarea
                className="mt-10 rounde-lg  px-2 border-b-2 focus-visible:outline-none border-green w-full"
                value={values.note}
                onChange={handleChange}
                name="note"
              ></textarea>
            </div>
            <button className="w-full bg-[#033d44] rounded-md max-w-[400px] justify-center items-center flex py-4  px-4 text-white my-10 mx-auto" type="submit">
        Submit
      </button>
          </form>
        </div>
      )}

