import { api } from "config";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useTrademarkAnalysis } from "state/trademarkAnalysis/hooks";

export const useTrademarkAnalysisData = () => {
  const {
    setTrademarkAnalysis,
    tableRecords,
    showResults,
    setMissingProductTerm,
    missingProductTerm,
    uniqueOwner,
    missingProductTermInCountry,
    setUniqueOwner,
    setMissingProductTermInCountries,
    setCountryDistribution,
    countryDistribution,
    setMissingMark,
    missingMark,
    setShowResults
  } = useTrademarkAnalysis();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [value, setValue] = useState("");
  const [polling, setPolling] = useState(false);
  const [show, setShow] = useState(false)
  const [uniqueOwnerField, setUniqueOwnerField] = useState([])
  const [ownerDiscrepancy, setOwnerDiscrepancy] = useState([])
  const [unusedTrademarks, setUnusedTrademarks] = useState([])
  const [unusedTrademarksCount, setUnusedTrademarksCount] = useState(0)
  const [uniqueTotal, setUniqueTotal] = useState<any>()
  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await api.post("process_excel", formData, {
        // // Commented out the progress tracking for brevity
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setValue(`${loaded} bytes of ${total} bytes | ${percent}%`);
          setUploadPercentage(percent);
        },
      });
      return data;
    } catch (error: unknown) {
      console.log("error", error);
      throw error;
    }
  };
  const getOwnerDiscrepancy = async () => {
    try {
      const ownerDiscrepancy = await api.get("owner_discrepancy?page=1");
      setUniqueOwner(ownerDiscrepancy.data.data.unique_owners)
      setUniqueTotal(ownerDiscrepancy.data.total_unique_owners)

      // setUniqueOwner(ownerDiscrepancy.data[1].unique_owners);
    } catch (error) {
      console.error("Error fetching owner discrepancy data:", error);
    }
  };
  const getUnusedTrademarks = async () => {
    try {
      const { data } = await api.get("unused_trademarks?page=1&size=10");
      setUnusedTrademarks(data.data)
      setUnusedTrademarksCount(data.total)
    } catch (error) {
      console.error("Error fetching owner discrepancy data:", error);
    }
  };
  const checkStatus = async () => {
    try {
      const { data } = await api.get("process_status", {
        // Commented out the progress tracking for brevity
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setValue(`${loaded} bytes of ${total} bytes | ${percent}%`);
          setUploadPercentage(percent);
        },
      });
      return data;
    } catch (error: unknown) {
      console.error("Error checking status:", error);
      throw error;
    }
  };

  const fetchAnalysisData = async () => {
    try {
      const { data } = await api.get("analyze_trademark");

      return data;
    } catch (error: unknown) {
      console.error("Error fetching analysis data:", error);
      throw error;
    }
  };

  const processFile = async (file: any) => {
    try {
      setShowResults(false)
      setUploadPercentage(0)
      await uploadFile(file);
      setPolling(true);
      const pollInterval = setInterval(async () => {
        try {
          const statusResponse = await checkStatus();
          if (statusResponse.status === "Completed") {
            clearInterval(pollInterval);
            setPolling(false);
            setUploadPercentage(100); // Set to 100% after processing completes
            const analysisData = await fetchAnalysisData();
            setTrademarkAnalysis(analysisData.results);
            setShowResults(true)
            await Promise.all([
              getMissingProductTermInCountries(),
              getOwnerDiscrepancy(),
              getMissingProductTerm(),
              getCountryDistribution(),
              getMissingMark(),
              getUnusedTrademarks()
            ]);
          } else {
            // Increment the progress gradually between 50% and 100%
            setUploadPercentage((prev) => Math.min(prev + 5, 99));
            setShow(true);
          }
        } catch (error) {
          clearInterval(pollInterval);
          setPolling(false);
          handleError(error);
        }
      }, 1000); // Poll every 4 seconds
    } catch (error) {
      handleError(error);
    }
  };


  const handleError = (e: unknown) => {
    let message = "Something went wrong";
    if (e instanceof Error) {
      message = e.message;
    }
    toast.error(message);
  };
  const getClassesData = useCallback(async (className: string) => {
    try {
      const { data } = await api.get(`classes_info?class_name=${className}`);
      const sanitizedData = data.replace(/NaN/g, "null"); // Replace NaN with null
      const jsonData = JSON.parse(sanitizedData);
      return jsonData;
    } catch (e: unknown) {
      handleError(e);
    }
  }, []);
  const getStatusData = useCallback(async (className: string) => {
    try {
      const { data } = await api.get(`status_info?status=${className}`);
      const sanitizedData = data.replace(/NaN/g, "null"); // Replace NaN with null
      const jsonData = JSON.parse(sanitizedData);
      return jsonData;
    } catch (e: any) {
      console.log(e);
      toast.error(e);
    }
  }, []);
  const getMissingProductTermInCountries = async () => {
    try {
      const missingProductTermInCountries = await api.get(
        "missing_product_term_country_chart"
      );

      setMissingProductTermInCountries(missingProductTermInCountries.data.data);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  }
  const getMissingMark = async () => {
    try {
      const missing_mark = await api.get(
        "missing_marks_chart"
      );

      setMissingMark(missing_mark.data);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  };
  const getCountryDistribution = async () => {
    try {
      const countryDistribution = await api.get(
        "country_distribution_chart"
      );

      setCountryDistribution(countryDistribution.data.country_distribution);
    } catch (error) {
      console.error(
        "Error fetching missing product term in countries data:",
        error
      );
    }
  };
  const getMissingProductTerm = async () => {
    try {
      const missingProductTerm = await api.get("missing_product_term_chart");
      setMissingProductTerm(missingProductTerm.data.data);
    } catch (error) {
      console.error("Error fetching missing product term data:", error);
    }
  };
  return {
    processFile,
    uploadPercentage,
    value,
    setUploadPercentage,
    tableRecords,
    showResults,
    missingProductTerm,
    missingProductTermInCountry,
    uniqueOwner,
    polling,
    getClassesData,
    getStatusData,
    getMissingProductTermInCountries,
    getMissingProductTerm,
    getCountryDistribution,
    countryDistribution,
    missingMark,
    show,
    uniqueOwnerField,
    uniqueTotal,
    ownerDiscrepancy,
    unusedTrademarks,
    unusedTrademarksCount
  };
};

